import OrderbookPanel from "@/views/dashboard/component/OrderbookPanel";
import TradeListPanel from "@/views/dashboard/component/TradeListPanel";
import SymbolTickerList from "@/views/dashboard/component/SymbolTickerList";
import BreadCrumbs from "@/views/dashboard/component/BreadCrumbs";
import SelectorMulti from "@/views/dashboard/component/SelectorMulti";
import SelectorSingle from "@/views/dashboard/component/SelectorSingle";
import StrategyInfoPanel from "@/views/dashboard/component/StrategyInfoPanel";
import SelectedItemTable from "@/views/dashboard/component/SelectedItemTable";

import ConfirmDialog from "@/components/base/ConfirmDialog";

import {
    get_account_list,
    get_strategy_param,
    get_strategy_orders,
    get_strategy_positions,
    update_strategy_param,
    get_strategy_status,
    place_order,
} from "@/api";

import { parse_depth, parse_symbolticker, roundN, } from "@/utils";

import { updateExchangeInfoMixin, routerMixins, } from "@/mixins";

import {
    INITIAL_MARGIN, INITIAL_LONG_PERCENTAGE,
    INITIAL_LEVERAGE, OVERLAY_TIMEOUT,
} from "@/constants";

import * as _ from 'underscore';

const ORDER_TYPE = 'TWAP'

export default {
    mixins: [routerMixins, updateExchangeInfoMixin],

    components: {
        OrderbookPanel,
        TradeListPanel,
        SymbolTickerList,
        SelectorSingle,
        SelectorMulti,
        ConfirmDialog,
        BreadCrumbs,
        StrategyInfoPanel,
        SelectedItemTable,
    },

    destroyed() {
        clearInterval(this.timer);
        clearInterval(this.timerSlow);
    },

    mounted: function () {
        this.strategy_name = this.$router.currentRoute.name

        this.instance_id = this.$router.currentRoute.query['instanceId']

        this.accountType = this.$router.currentRoute.query['accountType']

        this.breadcrumbs_items = [
            {
                text: "Strategies",
                disabled: false,
                href: "/pages/strategies",
            },
            {
                text: this.strategy_name,
                disabled: false,
                href: `/pages/strategies/details/${this.strategy_name}`,
            },
        ],

            this.$nextTick(async () => {
                await this.initData();
            })

    },
    watch: {
    },

    computed: {
        createDisabled() {
            if (this.gridFloor === undefined || this.gridCeiling === undefined || this.numOfGrid === undefined
                || this.gridFloor === '' || this.gridCeiling === '' || this.numOfGrid === '') {
                return true;
            }
            return false;
        },
        baseAsset() {
            if (this.spotExchangeInfo !== undefined && this.spotExchangeInfo !== null && this.spotExchangeInfo !== {} 
                && this.longSelect !== undefined && this.longSelect !== null && this.longSelect !== '') {
                return this.spotExchangeInfo[this.longSelect].baseAsset
            } else {
                return ''
            }
        },
        quoteAsset() {
            if (this.spotExchangeInfo !== undefined && this.spotExchangeInfo !== null && this.spotExchangeInfo !== {} 
                && this.longSelect !== undefined && this.longSelect !== null && this.longSelect !== '') {
                return this.spotExchangeInfo[this.longSelect].quoteAsset
            } else {
                return ''
            }
        },
        isSubmitDisabled() {
            return (Object.keys(this.spotExchangeInfo).length === 0);
        },

        isResetDisabled() {
            return false;
        },

        isUpdateDisabled() {
            return (!this.validParam(this.profitTarget) && !this.validParam(this.stopTarget))
                || (this.profitTarget === this.profitTargetOrigin && this.autoCover === this.autoCoverOrigin
                    && this.stopTarget === this.stopTargetOrigin && this.autoStop === this.autoStopOrigin);
        },

        confirmPlaceTxt() {
            return `Are you sure to place ${this.orderType} order?`;
        },
        lotSize() {

            if (this.spotExchangeInfo === undefined || !this.spotExchangeInfo.hasOwnProperty(this.longSelect)) {
                return ''
            }
            const symbolInfo = this.spotExchangeInfo[this.longSelect];

            return `Min:${symbolInfo.minQtyNew} Max:${symbolInfo.maxQtyNew}`
            
        }
    },

    data() {
        return {
            gridFloor: undefined,
            gridCeiling: undefined,
            numOfGrid: undefined,
            triggerPrice: undefined,
            stopLossPrice: undefined,
            takeProfitPrice: undefined,
            gridType: 'Arithmetic',
            maxSlippage: 0.01,
            autoMaxOrderSize: true,
            orderbookPert: 0.25,
            capital: 0,
            dealNum: 0,
            sumNum: 0,
            marketImpact: '0.00%',
            level: '',
            activeTasks: [],
            oldTasks: [],

            stopPrice: undefined,
            trailingDelta: 100,
            totalQty: 0.01,
            side: 'BUY',
            twapParams: {
                orderSize: '',
                duration: '60',
                orderInterval: '15',
                priceLevel: '0',
                threshold: 0.5,
                priceLimit: 0,
                maxOrderSize: '0',
            },
            limitMode: 'Quick',
            limitPrice: 0,
            gatewayName: '',
            strategy_name: '',
            instance_id: '',
            accountType: '',
            strategy_status: 'UNKNOWN',
            breadcrumbs_items: [],

            overlay: false,

            pnlRatio: 0,
            pnlTotal: 0,
            maxPnlTotal: 0,
            marginTotal: 0,
            params: {},

            open_orders: [],
            historical_orders: [],
            positions: [],
            isManaged: true,
            spotSymbolList: [],
            spotExchangeInfo: {},
            dlgSubscribe: false,
            dlgGrid: false,
            longPriceLevel: "b2",
            shortPriceLevel: "a2",
            priceTags: ["a5", "a4", "a3", "a2", "a1", "b1", "b2", "b3", "b4", "b5"],
            long_percent: INITIAL_LONG_PERCENTAGE,
            long_percent_items: [0.1, 0.2, 0.5, 0.7, 1],
            rules: [(value) => !!value || "Required."],
            orderType: ORDER_TYPE,
            leverage_items: [1, 3, 5, 10, 20, 50],
            leverage: INITIAL_LEVERAGE,
            timer: -1,
            timerSlow: -1,
            orderTable: [],
            subscribedItems: [],
            longSelect: '',
            symboltickers: [],
            symbolDefList: [],
            symbols: [],
            abdatas: {},
            hasAbDatas: {},
            tickerList: [],
            tickerDatas: {},
            upperColor: "#56f755",
            lowerColor: "#ff0000",
            obSize: ["50%", "50%"],
            tradeSize: ["30%", "30%", "40%"],
        };
    },

    methods: {

        updateMarketImpact() {

            this.capital = 0;
            this.dealNum = 0;
            this.sumNum = 0;

            if (this.totalQty !== undefined && this.totalQty.toString().trim() !== '') {

                const ask_key = `${this.longSelect}_${this.accountType}_asks`
                const bid_key = `${this.longSelect}_${this.accountType}_bids`
                const asks = this.abdatas[ask_key]
                const bids = this.abdatas[bid_key]

                if (this.side === 'BUY' && asks !== undefined) {
                    let i = asks.length - 1
                    let finish = false
                    let counter = i
                    for (; i >= 0; i--) {
                        this.sumNum += asks[i].qty
                        if (!finish) {
                            const delta = this.totalQty - this.dealNum
                            if (delta <= asks[i].qty) {
                                this.capital += delta * asks[i].price
                                this.dealNum = this.totalQty
                                finish = true
                                counter = i
                            } else {
                                this.capital += asks[i].qty * asks[i].price
                                this.dealNum += asks[i].qty
                                counter = i
                            }
                        }
                    }

                    let prefix = ''

                    if (counter === 0) {
                        if (this.dealNum < this.totalQty) {
                            prefix = 'over'
                        }
                    }

                    this.marketImpact = `${prefix} ${(((asks[counter].price - asks[asks.length - 1].price) / asks[asks.length - 1].price) * 100).toFixed(4)}%`
                    this.level = `ask ${asks.length - counter}`;
                } else if (this.side === 'SELL' && bids !== undefined) {
                    let i = 0
                    let finish = false
                    let counter = i
                    for (; i < bids.length; i++) {
                        this.sumNum += bids[i].qty
                        if (!finish) {
                            const delta = this.totalQty - this.dealNum
                            if (delta <= bids[i].qty) {
                                this.capital += delta * bids[i].price
                                this.dealNum = this.totalQty
                                finish = true
                                counter = i
                            } else {
                                this.capital += bids[i].qty * bids[i].price
                                this.dealNum += bids[i].qty
                                counter = i
                            }
                        }
                    }

                    let prefix = ''

                    if (counter === bids.length - 1) {
                        if (this.dealNum < this.totalQty) {
                            prefix = 'over'
                        }
                    }

                    this.marketImpact = `${prefix} ${(((bids[counter].price - bids[0].price) / bids[0].price) * 100).toFixed(4)}%`
                    this.level = `bid ${counter + 1}`;
                }

                this.capital = this.capital.toFixed(4)
                this.dealNum = this.dealNum.toFixed(4)
                this.sumNum = this.sumNum.toFixed(4)

            }

        },

        preSubmit() {
            const ask_key = `${this.longSelect}_${this.accountType}_asks`
            const bid_key = `${this.longSelect}_${this.accountType}_bids`
            const asks = this.abdatas[ask_key]
            const bids = this.abdatas[bid_key]

            if (this.side === 'BUY') {
                this.twapParams.priceLimit = asks[asks.length - 1].price * (1 + this.maxSlippage)
            } else {
                this.twapParams.priceLimit = bids[0].price * (1 - this.maxSlippage)
            }

            if (this.autoMaxOrderSize) {
                this.twapParams.maxOrderSize = this.sumNum * this.orderbookPert
            }

            let rate = (this.totalQty / this.twapParams.maxOrderSize) * 2.5

            if (rate < 1) {
                rate = 1
            }

            this.twapParams.duration = (this.twapParams.orderInterval * rate).toFixed(0)
        },

        async initData() {

            this.accounts = {};

            this.tickerList = [];
            this.tickerDatas = {};
            this.orderTable = [];
            this.abdatas = {};
            this.hasAbDatas = {};

            this.sockets.subscribe("Depth", (event) => {
                const { symbol, accountType, asks, ask_key, bids, bid_key } =
                    parse_depth(event);

                if (this.symbols.indexOf(symbol) >= 0 && accountType === this.accountType) {
                    this.abdatas[ask_key] = asks;
                    this.abdatas[bid_key] = bids;
                    this.hasAbDatas[symbol] = true;
                }

            });

            this.sockets.subscribe("SymbolTicker", (event) => {
                const { symbol, accountType, ev, key } = parse_symbolticker(event);

                if (this.symbols.indexOf(symbol) >= 0 && accountType === this.accountType) {
                    this.tickerDatas[key] = ev;
                }

            });

            get_account_list().then((acc) => {
                this.accounts = acc.accounts;
            })

            this.fetchExchangeInfo().then(({
                new_total_infos, }) => {
                const exchange = new_total_infos[this.accountType]
                if (exchange !== undefined) {
                    this.spotSymbolList = exchange.filterLst;
                    this.spotExchangeInfo = exchange.exchangeInfo;
                }
            })

            get_strategy_param(this.strategy_name, this.instance_id)
                .then((strategy_param) => {

                    this.gatewayName = strategy_param.gateways[0];

                    this.params = strategy_param.params;

                    this.symbolDefList = strategy_param.symbols;
                    this.symbols = strategy_param.symbols.map((item) => item.symbol);
                    this.longSelect = this.symbols[0];
                    this.subscribedItems = this.symbols;

                })
                .catch((reason) => {
                    console.error(reason);
                });

            this.timer = setInterval(() => {

                this.updateMarketImpact()

                this.tickerList = Object.keys(this.tickerDatas)
                    .sort()
                    .map((item) => this.tickerDatas[item]);

                get_strategy_tasks(this.strategy_name, this.instance_id).then((result) => {

                    if (result != undefined) {
                        this.activeTasks = result.activeTasks
                        this.oldTasks = result.oldTasks
                    } else {
                        this.activeTasks = []
                        this.oldTasks = []
                    }

                }).catch((reason) => {
                    console.error(reason);
                    this.logout()
                })

                get_strategy_positions(this.strategy_name, this.instance_id).then(({ positions, pnlTotal, maxPnlTotal, marginTotal, pnlRatio, }) => {

                    this.positions = positions;
                    this.pnlTotal = pnlTotal;
                    this.maxPnlTotal = maxPnlTotal;
                    this.marginTotal = marginTotal;
                    this.pnlRatio = pnlRatio;
                }).catch((reason) => {
                    console.error(reason);
                    this.logout()
                })

                get_strategy_status(this.strategy_name, this.instance_id).then((status) => {
                    this.strategy_status = status;
                }).catch((reason) => {
                    console.error(reason);
                });

                this.$forceUpdate();

            }, 3000);

            this.timerSlow = setInterval(() => {

                get_strategy_orders(this.strategy_name, this.instance_id).then(({ open_orders, historical_orders, }) => {
                    this.open_orders = open_orders;
                    this.historical_orders = historical_orders;
                }).catch((reason) => {
                    console.error(reason);
                    // this.logout()
                })

                this.$forceUpdate();
            }, 6000);
        },

        validParam(param) {
            return (!_.isNull(param) && !_.isUndefined(param)) && param !== ''
        },

        noSelection() {
            return this.longSelect.length == 0;
        },

        updateParams() {
            const params = {
                profitTarget: this.profitTarget,
                autoCover: this.autoCover,
                stopTarget: this.stopTarget,
                autoStop: this.autoStop,
            }

            update_strategy_param({ strategyName: this.strategy_name, instanceId: this.instance_id, params, updateType: "PARAMS", }).then((strategy_param) => {
                this.params = strategy_param.params;
            })
        },

        tryToSubscribe() {
            this.dlgSubscribe = true;
        },
        closeDlgSubscribe() {
            this.dlgSubscribe = false;
        },
        subscribe() {

            const symbols = this.subscribedItems.map((symbol) => {
                return {
                    orderAmt: 0,
                    orderQty: 0,
                    symbol,
                    accountType: this.accountType,
                }
            })

            this.closeDlgSubscribe();

            update_strategy_param({ strategyName: this.strategy_name, instanceId: this.instance_id, symbols, updateType: "SYMBOLS", }).then((strategy_param) => {
                console.log(strategy_param)
            })

            this.overlay = true;
            setTimeout(() => {
                this.initData();
                this.reset();
                this.overlay = false;
            }, OVERLAY_TIMEOUT)

        },

        tryToCreateGrid() {
            this.dlgGrid = true;
        },
        closeDlgCreateGrid() {
            this.dlgGrid = false;
        },
        createGrid() {
            this.closeDlgCreateGrid();
        },

        onPercentChange(e) {
            this.twapParams.threshold = e;
        },
        onSelectedItemsChange(e) {
            this.subscribedItems = e;
        },
        onBSChange(e) {
            this.side = e;
        },
        onPriceLevel(e) {
            this.twapParams.priceLevel = e;
        },
        onLongChange(e) {
            this.longSelect = e;
        },
        reset() {
            this.marginInput = INITIAL_MARGIN;
            this.leverage = INITIAL_LEVERAGE;
            this.long_percent = INITIAL_LONG_PERCENTAGE;
            this.orderType = ORDER_TYPE;
            this.long_percent_items = [0.1, 0.2, 0.5, 0.7, 1];
            this.leverage_items = [1, 3, 5, 10, 20, 50];
            this.$EventBus.$emit("ls_clear");
            this.longSelect = '';
        },
        getPrice(symbol, orderType, direction) {

            if (orderType === 'MARKET') {
                return 0
            }

            const longPriceLevel = direction === 'LONG' ? this.longPriceLevel : this.shortPriceLevel;

            const isAsk = longPriceLevel.substring(0, 1) === 'a';

            const key_suffix = isAsk ? 'asks' : 'bids';

            const level = parseInt(longPriceLevel.substring(1, 2))

            const key = `${symbol}_USDT_FUTURE_${key_suffix}`

            const priceItem = isAsk ? this.abdatas[key][this.abdatas[key].length - level] : this.abdatas[key][level - 1]

            return priceItem.price

        },
        submit() {

            if (this.orderType === 'MARKET' || this.orderType === 'LIMIT'
                || this.orderType === 'STOP_LOSS_LIMIT' || this.orderType === 'TAKE_PROFIT_LIMIT') {

                const strategyName = this.isManaged ? this.strategy_name : '';

                const instanceId = this.isManaged ? this.instance_id : '';

                const params = {
                    gatewayName: this.gatewayName,
                    symbol: this.longSelect,
                    accountType: this.accountType,
                    strategyName,
                    instanceId,
                    orderSide: this.side,
                    orderType: this.orderType,
                    qty: this.totalQty,
                    price: this.limitPrice,
                };

                if (this.orderType == 'LIMIT') {
                    params.timeInForce = 'GTC'
                } else if (this.orderType == 'STOP_LOSS_LIMIT' || this.orderType == 'TAKE_PROFIT_LIMIT') {
                    params.timeInForce = 'GTC'
                    params.stopPrice = this.stopPrice
                    params.trailingDelta = this.trailingDelta
                }

                place_order(params);

            } else {

                submit_strategy_task({
                    symbol: this.longSelect, accountType: this.accountType, totalQty: this.totalQty, side: this.side,
                    strategyName: this.strategy_name, instanceId: this.instance_id, type: this.orderType, params: this.twapParams,
                }).then((res) => {
                    console.log(res)
                }).catch((reason) => {
                    console.error(reason)
                })
            }

            this.closeDlgPlace();
        },

        resetSubscription() {
            console.log('resetSubscription')
            const temp = this.symbols
            this.symbols = [];
            setTimeout(() => {
                this.symbols = temp;
            }, 0)
        },
    },
};
